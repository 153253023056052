import React from "react"
import PropTypes from "prop-types"
import { Card } from "antd"

import "./CardWithIcon.less"

class CardWithIcon extends React.Component {
  render() {
    return (
      <Card bordered={false} className={`card-icon ${this.props.theme}`}>
        <div className="card-icon-meta">{this.props.children}</div>
      </Card>
    )
  }
}

CardWithIcon.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(["outline", "fill"]),
}

CardWithIcon.defaultProps = {
  theme: "outline",
}

export default CardWithIcon
